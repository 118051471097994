@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); /*-------------------App------------------------*/
html, body {
    scroll-behavior: auto;
}
.app_container{
    margin-top: 100px;
    height: 100%;
}
/*------------------Header----------------------*/
.App{
    display: flex;
    flex-direction: column;

}
.footer {
    margin-top: auto;
    border-top: 1px solid #00000024;
    padding: 20px;
}
.header{
    border-bottom: 1px solid #00000024;
    position: fixed;
    z-index: 3;
    top:0;
    width: 100%;
    height: 100px;
    background: white;
}
.star_img {
    width: 30px;
    margin-right: 10px;
}
.logo_text{
    color: black;
    border: none;
    background-color: transparent;
    font-family: 'Kanit', sans-serif;
    font-size: 26px;
}
.nav_menu{

}
.menu_list {
    list-style: none;
    margin-top: 16px;
}
.menu_item:last-child{
    border-right: none;
}
.menu_item{
    font-weight: 600;
    cursor: pointer;
    border-right: 1px solid #00000038;;
}

.menu_item {
    margin-left: 40px;
    padding-right: 22px;
    font-size: 20px;
}
.enter_img{
    margin-right: 25px;
    cursor: pointer;
}
.search_img{
    cursor: pointer;
}
/*----------------------Main--------------------*/
.bg-main_section1{
    background: linear-gradient(to bottom, rgb(169, 169, 169), rgb(255, 255, 255));
}
.card_container{
    flex: 1;
    padding: 20px;
    border: 1px solid #6d6d6d85;
    border-radius: 10px;
    margin-top: 20px;
}
.card_title{
    font-size: 23px;
    font-weight: 600;
}
.card_text{
    font-size: 18px;
}
.card_right_side{
    margin-left: 10px;
}
.hit_container{
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
}
.hit_card {
    display: flex;
    max-width: 500px;
    overflow: hidden;
    flex-direction: column;
    padding: 30px;
    border: 1px solid #120f0f3b;
    border-radius: 20px;
    justify-content: space-between;
    margin:10px
}
.top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00BAF0;
    background: linear-gradient(to left, #f46b45, #eea849);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #FFF;
    height: 50px;
    padding: 1em;
}

.menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu > li {
    margin: 0 1rem;
    overflow: hidden;
}

.menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#menu-toggle {
    display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu-button::before {
    content: '';
    margin-top: -8px;
}

.menu-button::after {
    content: '';
    margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}


.hit_card_img{
    width: 100%; /* Изображение будет занимать 100% ширины родительского контейнера */
    height: auto; /* Сохраняет соотношение сторон изображения */
    display: block; /* Убирает лишние отступы отображения */
}
.hit_title{
    font-size: larger;
    font-weight: 700;
}
.hit_btn{
    border: 1px solid #ed4918;
    background-color: red;
    color: white;
    padding: 10px 25px;
    border-radius: 5px;
    font-weight: 600;
    transition: background-color 0.3s;
}
.hit_btn:hover{
    background-color: white;
    color: red;
}
.hit_text{
    padding: 10px 0;
}
.hit_price{
    font-weight: 600;
}
.sale_container{
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
}
.sale_card{
    display: flex;
    max-width: 340px;
    overflow: hidden;
    flex-direction: column;
    padding: 30px;
    border: 1px solid #120f0f3b;
    border-radius: 20px;
    justify-content: space-between;
    margin:10px
}
.NotSale-price{
    text-decoration: line-through;
}
.sale_price{
    margin-left: 10px;
    color:red;
}
.drop_text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 0;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 0, 0, 0.48);
    border-radius: 10px;
    font-weight: 600;
}
.custom_number_input.basket{
    width: 30px;
}
.custom_number_input:focus{
    border: none;
    outline: none;
}
.dropdown-center .nav-item.menu_item{
    border: none;
    background-color: transparent;
    outline: none;
    border-right: 1px solid #00000038;
}
/*.dropdown-center .nav-item.menu_item{*/
/*    border: none;*/
/*    background-color: transparent;*/
/*    font-size: 25px;*/
/*    background: #6d6d6d;*/
/*    color: white;*/
/*    font-weight: 600;*/
/*    border-radius: 10px;*/
/*    margin-top: 10px;*/
/*    cursor: pointer;*/
/*}*/
.dropdown-menu.centered-dropdown .dropdown-item .active{

}
.nav-link.nav-tabs-link{
    color: black;
}
.nav-link.nav-tabs-link.active {
    background-color: red!important;
    color:white;
}
.nav-link.custom-nav-link.active{
    background-color: red!important;
    color: white!important;
}
.nav-link.custom-nav-link{
    margin: 0 50px;
    padding: 10px 35px;
    color: black!important;
}

.nav-tabs{
    border-bottom: none;
}
.max-h{
    max-height: 100vh!important;
}
.custom_previous{
    text-decoration: none;
    background-color: #f1f1f1;
    border-radius: 50%;
    color: black;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 74px;
    margin: 0;
    padding: 0;
}
.custom_modal{
    position: absolute;
    min-height: 100vh;
    min-width: 100vh;
    width: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);

}
.available{
    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgba(47, 185, 11, 0.2);
    color: #2fb90b;
    display: inline-block;
    background-size: auto 22px;
}
.sale-price{
    color: red;
}
.plus_minus_btn{
    margin: 7px 3px;
    border: none;
    opacity: 0.7;
    background-color: transparent;
    transition: opacity 0.3ms;
}
.plus_minus_btn:hover{
    opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.nav-tabs-link:hover{
    color: red!important;
}
.nav-tabs-link.active:hover{
    color: white!important;
}
.cursor-pointer{
    cursor: pointer!important;
}
.privacy-policy-marker::marker{
    color: red!important;
}
.dropdown-menu.centered-dropdown{
    margin: 0!important;
    padding: 0!important;
}

.custom_input:focus{
    border: 1px solid red;
    outline: none;
    box-shadow: none;
}
.none_btn{
    border: none!important;
    background-color: transparent;
    outline: none!important;
    box-shadow: none!important;
}
.basket-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    margin: 10px 5px;
    border-radius: 10px;
}

.offcanvas{
    width: 35vw!important;
}
.offcanvas-container {
    overflow-y: auto;
    max-height: 100vh;
}
.login-form_container{
    width: 50%;
}
.custom_view_column{
    min-height: 600px;
    max-height: 600px;
}
.custom_number_input{
    text-align: center;
    border: none;
    background-color: transparent;
    width: 70px;
    padding: 0 5px;
}

.custom_number_input:focus{
    border: none;
    outline: none;
}
.dropdown-center .nav-item.menu_item{
    border: none;
    background-color: transparent;
    outline: none;
}
.dropdown-center .nav-item.menu_item{
    border: none;
    background-color: transparent;
}
.centered-dropdown{
    transform: translate(-64px, 34px)!important;
}
.dropdown-item:hover{
    background-color: red;
    color: white;
}

.dropdown-menu.centered-dropdown{
    margin: 0!important;
    padding: 0!important;
}

.popular_category_container{
    display: flex;
    justify-content: space-around;
}

#header_dropdown{
    transform: translate3d(-40px, 40px, 0px)!important;
}

.btn:focus {
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
}
.btn:focus-visible{
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
}
.burger_menu_container{
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
}

.burger_menu .modal-backdrop.fade.show{
    background-color: transparent;
}
#burger_menu_accordion_button {

}
#basket_accordion_button.accordion-button:not(.collapsed),
#burger_menu_accordion_button.accordion-button:not(.collapsed) {
    color: white!important;
    background-color: #f50000;
    box-shadow: none;
}
#accordionExample .accordion-body{
    margin-bottom: 10px;
}
#accordionBasket .accordion-body,
#accordionExample .accordion-body{
    padding: 0!important;
    border: 1px solid #fab7b7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
#accordionBasket.accordion,
#accordionBasket .accordion-item,
#accordionExample .accordion,
#accordionExample .accordion-item{
    border: none;
}

#accordionBasket.body,
#accordionExample .body{
    border: 1px solid red;
}

.custom_accordion_item {
    width: 100%;
    padding: 16px 20px;
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}

#burger_menu_accordion_button:focus,
#burger_menu_accordion_button:active {
    outline: none;
    border: none;
    box-shadow: none;
}
.zoom_photo{
    max-width: 60vw;
    max-height: 400px;
}
#offcanvasScrolling{
    width: 80vh!important;
}

@media screen and (max-width: 1200px){
.login-form_container{
    width: 70%;
}
    .custom_view_column{
        flex-direction: column-reverse!important;
        max-height: 100%!important;
        align-items: center;
    }
    .custom_info-block{
        width: 100% !important;
    }
    #carouselExample{
        width: 500px;
    }
    .carousel-fix{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 500px){
    .login-form_container{
        width: 90%;
    }
    .custom-delivery_info{
        margin-right: 30px;
        flex-direction: column;
    }
    .custom_info-block{
        padding-right: 45px;
    }
    #carouselExample{
        width: 250px;
    }
    .plus_minus_btn{
        margin: 3px!important;
    }
    .custom_number_input.basket{
        padding: 3px;
    }
    .basket-item{
        padding: 8px 2px;
    }
}

@media (max-width: 700px) {
    .menu-button-container {
        display: flex;
    }
    .menu {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    #menu-toggle ~ .menu li {
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu li {
        border: 1px solid #333;
        height: 2.5em;
        padding: 0.5em;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu > li {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5em 0;
        width: 100%;
        color: white;
        background-color: #222;
    }
    .menu > li:not(:last-child) {
        border-bottom: 1px solid #444;
    }
    .zoom_photo{
        min-width: 100vw;
        min-height: 100vh;
        margin: 0!important;
    }
    .custom_info-block{
        padding: 0.5rem!important;
        margin: 0!important;
    }
    .custom-delivery_info{
        margin-right: 0;
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
    }
    #carouselExample{
        width: 100%;
    }
    #carouselExample .modal-body{
        padding: 0!important;
    }
    .nav-link.custom-nav-link{
        margin: 0!important;
        padding: 10px 15px;
    }
    #nav-characteristics{
        font-size: 14px;
    }
}
@media (max-width: 400px) {
    .logo_text{
        font-size: 18px;
    }
    .login_container{
        margin-bottom: 80px!important;
        margin-top: 50px!important;
    }
    .basket-item{
        padding-right: 0px!important;
        margin-right: 0px!important;
        padding-left: 0px!important;
        margin-left: 0px!important;

    }
    .basket-item-text{
        padding: 2px;
        font-size: 14px;
    }
    .basket-item-price{
        padding: 2px;
        font-size: 13px!important;
    }
    .plus_minus_btn{
        margin: 0!important;
        padding: 0 4px;
    }
    .custom_number_input.basket{
        padding: 0!important;
        font-size: 14px;
    }
    .full_price{
        font-size: 15px;
    }
}

@media (min-width: 973px) {
    .nav_menu{
        display: flex!important;
    }
    .basket_user_nav{
        display: block;
    }
    .burger_menu{
        display: none!important;
    }

}
@media (max-width: 972px) {
    .header{
        padding:  0 30px;
        justify-content: space-between!important;
    }
    .nav_menu{
        display: none;
    }
    .basket_user_nav{
        display: none!important;
    }
    .burger_menu{
        display: block!important;
    }
    .bg-main_section1{
        display: none;
    }
    .footer_container{
        flex-wrap: wrap;
        justify-content: left!important;
    }
    .footer_item{
        margin-bottom: 10px;
    }
    .advantages_container{
        flex-direction: column;
    }
    .advantages_container1{
        margin-right: 0!important;
        margin-left: 0!important;
    }
    .hit_container{
        flex-direction: column;
        align-items: center!important;
    }
    .popular_category_container{
        flex-direction: column;
        align-items: center!important;
    }
    .header{
        padding: 0 5px;
    }
    .sale_card{
        max-width: 500px;
    }
    .help_container{
        flex-direction: column;
    }
    .nav_tabs_help{
        display: flex;
        width: 100%;
    }
    .title_view_product{
        text-align: center;
        font-size: 28px;
    }
    .available{
        text-align: center;
    }
    .in_stock_product_code{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .price_and_buy_button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

}
